import { useInfiniteQuery, useQueryClient } from '@tanstack/vue-query'
import { uniqBy } from 'lodash-es'

export const useClientTasks = (projectId = null) => {
    const { execute } = useApi('/api/accountant/projects/client-tasks', 'GET')

    const {
        data: clientTasks,
        isFetching,
        fetchNextPage: fetchMore,
        hasNextPage: hasMore,
    } = useInfiniteQuery({
        queryKey: ['accountant-projects-client-tasks', projectId],
        queryFn: ({ pageParam }) => {
            let queryParams = {
                cursor: pageParam,
                limit: 20,
            }

            if (projectId) {
                queryParams = {
                    ...queryParams,
                    filter: {
                        project_id: projectId,
                    },
                }
            }

            return execute({
                queryParams,
            })
        },
        getNextPageParam: ({ next_cursor }) => next_cursor,
        select: (res) =>
            uniqBy(
                res?.pages?.flatMap((x) => x?.data),
                (x) => x?.id
            ),
        refetchOnWindowFocus: false,
        refetchInterval: false,
    })

    const onLoadMore = () => {
        if (hasMore.value) {
            fetchMore()
        }
    }

    return {
        onLoadMore,
        clientTasks,
        isFetching,
    }
}

export const useClientTask = (projectId = null) => {
    const queryClient = useQueryClient()

    const { execute: toggleStatusSubTask } = useApi(
        '/api/accountant/projects/client-tasks/{id}/sub-tasks/{subTaskId}',
        'PATCH'
    )

    const newClientTask = (clientTask) => {
        queryClient.setQueriesData(
            {
                queryKey: ['accountant-projects-client-tasks', projectId],
            },
            (data) => ({
                pages:
                    data.pages?.map((page, index) => {
                        if (index === 0) {
                            return {
                                ...page,
                                data: [clientTask, ...page.data],
                            }
                        }

                        return page
                    }) ?? [],
                pageParams: data.pageParams,
            })
        )
    }

    const updateClientTask = (clientTask) => {
        queryClient.setQueriesData(
            {
                queryKey: ['accountant-projects-client-tasks', projectId],
            },
            (data) => ({
                pages:
                    data.pages?.map((page) => {
                        return {
                            ...page,
                            data: page.data.map((i) =>
                                i.id === clientTask.id ? clientTask : i
                            ),
                        }
                    }) ?? [],
                pageParams: data.pageParams,
            })
        )
    }

    const upsertSubTask = (subTask) => {
        queryClient.setQueriesData(
            {
                queryKey: ['accountant-projects-client-tasks', projectId],
            },
            (data) => ({
                pages:
                    data.pages?.map((page) => {
                        return {
                            ...page,
                            data: page.data.map((clientTask) => {
                                if (clientTask.id !== subTask.client_task_id) {
                                    return clientTask
                                }

                                const idx = clientTask.sub_tasks.findIndex(
                                    (i) => i.id === subTask.id
                                )

                                if (idx > -1) {
                                    return {
                                        ...clientTask,
                                        sub_tasks: clientTask.sub_tasks.map(
                                            (i) =>
                                                i.id === subTask.id
                                                    ? subTask
                                                    : i
                                        ),
                                    }
                                }

                                return {
                                    ...clientTask,
                                    sub_tasks: [
                                        ...clientTask.sub_tasks,
                                        subTask,
                                    ],
                                }
                            }),
                        }
                    }) ?? [],
                pageParams: data.pageParams,
            })
        )
    }

    const changeStatusSubTask = (clientTaskId, subTaskId) => {
        return toggleStatusSubTask({
            params: {
                id: clientTaskId,
                subTaskId,
            },
        }).then((subTask) => upsertSubTask(subTask))
    }

    return {
        newClientTask,
        updateClientTask,
        upsertSubTask,
        changeStatusSubTask,
    }
}
